<script>
import accountMixin from "@/mixins/accounts.js";
import View from "@/views/View";
import LoadSections from "@/mixins/load-sections";
export default {
  name: "Loans",
  metaInfo: { title: "Loans" },
  extends: View,
  mixins: [accountMixin, LoadSections(["recent-loans", "info"])],
  props: {
    id: {
      type: String,
      default: "about",
    },
  },
};
</script>
